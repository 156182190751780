import React, { Component } from "react";
import {
  apiURL,
  apiHeader,
  apiPUTHeader,
  apiPOSTHeader,
  apiDELETEHeader,
} from "../../resources/apiURL";
import MenuIcon from "../../resources/menu.png";
import Plus from "../../resources/plus.svg";
import Refresh from "../../resources/refresh.svg";
import Excel from "../../resources/excel.svg";
import "../../custom.css";

import { ExcelUtility } from "../../operation/ExcelUtility";

import { Col, Row, Button, FormGroup, Label, Input } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from 'react-select';
// import { IgrDataGrid } from "igniteui-react-grids";

// import { IgrDataGridModule } from "igniteui-react-grids";
// import { IgrTextColumn } from "igniteui-react-grids";
// import { IgrNumericColumn } from "igniteui-react-grids";
// import { IgrGridColumnOptionsModule } from "igniteui-react-grids";
// import { IgrDatePickerModule } from "igniteui-react-inputs";
import { Workbook } from "igniteui-react-excel";
import { WorkbookFormat } from "igniteui-react-excel";
import { getUserADAccount, getRandomInt } from "../../resources/utility";
// import { IgrTemplateColumn } from "igniteui-react-grids";
import { ColoredLine, getFirstDayofMonth } from "../../resources/utility";
import PowerBIReportViewer from "../../resources/PowerBIReportViewer";
import Revise from "../../resources/revise.png";
import Report from "../../resources/report.png";
import moment from "moment";
// import { IgrDataGridToolbarModule } from "igniteui-react-grids";
// import { IgrDataGridToolbar } from "igniteui-react-grids";

import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort, Resize, ExcelExport, Toolbar, Reorder  } from '@syncfusion/ej2-react-grids';
import PORequestDetails from './PORequestDetails'

// IgrDataGridModule.register();
// IgrGridColumnOptionsModule.register();
// IgrDatePickerModule.register();
// IgrDataGridToolbarModule.register();

var DatePicker = require("reactstrap-date-picker");

export default class PORequest extends Component {
  static displayName = PORequest.name;

  constructor(props) {
    super(props);
    this.gridRef = React.createRef();
    document.title = "WaterBridge Portal - PO Request";

     this.onGridRef = this.onGridRef.bind(this);
     this.onGridRowSelected = this.onGridRowSelected.bind(this);
     this.saveGridLayout = this.saveGridLayout.bind(this);
     this.loadGridLayout = this.loadGridLayout.bind(this);
    // this.onGridfilterExpressionsChanged = this.onGridfilterExpressionsChanged.bind(this);
    //  this.onToolbarRef = this.onToolbarRef.bind(this);

    // this.saveWorkbook = this.saveWorkbook.bind(this);
    // this.onStatusCellUpdating = this.onStatusCellUpdating.bind(this);
     const urlPara = this.getURLParameters(props.location)

    this.state = {
      pageSize: 25,
      poReqData: [],
      fullPOReqData:[],
      isPOReqLoaded: false,
      // startDate: (urlPara.startDate === null ? getFirstDayofMonth().toISOString() : urlPara.startDate), 
      // endDate: (urlPara.endDate === null ? this.addDays(Date(), 1).toISOString() : urlPara.endDate),
      startDate: getFirstDayofMonth().toISOString(),
      endDate: new Date().toISOString(),
      windowHeight: window.innerHeight,
      hideDialog:false,
      modalPODetails: false,
      poRequestKey: (urlPara.poRequestKey === null ? -1 : urlPara.poRequestKey),
      openPODetails: !(urlPara.poRequestKey === null),
      entity:[],
      isEntityLoaded:false,
      afeData: [],
      afeDataDefault: [],
      isAFELoaded: false,
      offLineLinkKey: null,
      poReqID: null,
      isSiteLoaded:false,
      siteData:[],
      siteDataDefault:[],
      isSpendCategoryLoaded: false,
      spendCategory: [],
      isSupplierLoaded:false,
      supplier: [],
      selPORequestQuery: [],
      selEntity: [],
      selStatus: [],
      status: [],
      isSecurityLoaded: false,
      security: [],
      searchText: "",
      isStatusLoaded: false,
      isSecurityAdminLogin: false,
      isGridLoaded:false,
      toolbar: ['ExcelExport'],
      sortedColumns: [], // Add an initial empty array for sortedColumns
      formatOption: { type: 'date', format: 'MM/dd/yyyy hh:mm:ss a' },
      poRequestQueryList: [
        {
          label: "My PO",
          value: 1,
        },
        {
          label: "All PO",
          value: 2,
        },
      ],
    };
  }

  getURLParameters = (inputPara) => {
    var paraMeters = {
        startDate: null,
        endDate: null,
        poRequestKey: null
    }
    var query
    if (inputPara !== undefined) {
        query = new URLSearchParams(inputPara.search);
        paraMeters = {
            // startDate: (query.get('startDate') !== null ? new Date(query.get('startDate')).toISOString() : null),
            // endDate: (query.get('endDate') !== null ? new Date(query.get('endDate')).toISOString() : null),
            poRequestKey: query.get('key')
        }


    }

    // console.log(paraMeters)
    return paraMeters
  }

  toolbarClick = (args) => {
    if (this.gridRef.current && args.item.id === 'grid_excelexport') {
      this.gridRef.current.excelExport({ fileName: 'PoRequest.xlsx' });
    }
  };

  openPODetailsFromKey = (poList) => {
    const { openPODetails} = this.state
    if (openPODetails) {
        const selPO = poList.filter(a => Number(a.poRequestKey) === Number(this.state.poRequestKey))
        if (selPO.length > 0) {
            this.setState({
                poReqID: selPO[0].poReqID,
                // status: selMOC[0].mocStatusDesc
            })
        }
 
         this.togglePODetails()
 
    }
}


  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}


  
handleChange(value, formattedValue) {
  this.setState({
      startDate: value,
      formattedValue: formattedValue
  });
  // console.log(this.state.startDate)
}

handleEndDateChange(value, formattedValue) {
  this.setState({
      endDate: value,
      formattedValue: formattedValue

  });

}
  onGridRef(grid) {
    if (!grid) {
        return;
    }
    this.grid = grid;
    this.grid.dataSource = this.state.poReqData;

    // if (this.toolbar !== null) {
    //     this.toolbar.targetGrid = this.grid

    // }

}

// onToolbarRef(toolbar) {
//     this.toolbar = toolbar;
//     if (this.toolbar) {
//         this.toolbar.targetGrid = this.grid;
//     }
// }







componentDidMount() {
  //this.getPOReqData()
  this.getSecurity()
  this.getSupplierEntity()
  this.getAFE()
  this.getSite()
  this.getSpendCategory()
  this.getSupplier()
  this.getStatus()
  this.loadGridLayout(); // Calling the bound function
  window.addEventListener('resize', this.handleResize);
  // this.setDefaultPOQuery()
}
componentWillUnmount() {
  window.removeEventListener('resize', this.handleResize);
}

componentDidUpdate = () => {
  if (!this.state.isGridLoaded && this.gridRef.current) {
    this.loadGridLayout();
    this.setState({ isGridLoaded: true });
  }
};


handleResize = () => {
  this.setState({ windowHeight: window.innerHeight });
}


onGridRowSelected(s, e) {
  const {fiixWOID} = this.state
  const content = e.content;
  if (e.addedKeys.count > 0) {
      let clickedRow = e.addedKeys.item(0)
      let dataItem = clickedRow.value[0]
      this.setState({
          poRequestKey: dataItem.poRequestKey,
          // woNumber: dataItem.woNumber,
          // bigFormat: true,
          // fiixWOId: dataItem.fiixWOId,
          // maintenanceTypeID: dataItem.maintenanceTypeID
      })

        // console.log(dataItem)
  }
}

rowSelected = () => {
  if (this.gridRef.current) {
    const selectedRecords = this.gridRef.current.getSelectedRecords();
    if (selectedRecords.length > 0) {
      const selectedRecord = selectedRecords[0]; // Assuming you're interested in the first selected record
      this.setState({
        poRequestKey: selectedRecord.poRequestKey, // Adjust this based on your record structure
        offLineLinkKey: selectedRecord.offLineLinkKey,
        poReqID: selectedRecord.poReqID,
      }); 
      // console.log(selectedRecords)
    }
  }
};



getPOReqData = () => {
  const { user } = this.props;
  let myURI = apiURL + 'SupplyChain/PurchaseOrderRequest'
      const userName = getUserADAccount(user.account.userName).toLowerCase();
  fetch(myURI, { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
          this.setState({
              isPOReqLoaded: true,
              poReqData: json.filter(a => a.createdBy === userName).length > 0 ? json : [],
              fullPOReqData: json,
              searchText: ""
          })
          this.getDefaultPOList(json)
          this.openPODetailsFromKey(json)
          // console.log(json)
      }); 

}

refreshDatafilter = () =>{
  this.getPOReqData()
  this.saveGridLayout()
  this.loadGridLayout()
}


getSecurity = () => {
  let myURI = apiURL + 'EmployeeDetails/Checkpoint/' + this.props.user.account.userName
  fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
          this.setState({
              isSecurityLoaded: true,
              security: json,
              // isSecurityAdminLogin: (json.filter((list) => list.appSecurityNo === 133).length > 0 ? true : false), //Preview2
              isSecurityAdminLogin: (json.filter((list) => list.appSecurityNo === 138).length > 0 ? true : false), //PROD

          },()=>{
            if(this.state.isSecurityAdminLogin === true)
            {
              this.setState({ selPORequestQuery: this.state.poRequestQueryList[1] });
            }
            else{
              this.setState({ selPORequestQuery: this.state.poRequestQueryList[0] });
            }
            this.getPOReqData()
         
          })

      })

}



createSupplierCatList = (value) => {
  var tempList = []
  value.map(e => {
      e.value = e.supplierReqCatKey
      e.label = e.supplierReqCatDesc
      tempList.push(e)
  })

  return tempList
}


getSpendCategory = () => {
  let myURI = apiURL + 'SupplyChain/PORequest/SpendCategory'

  fetch(myURI, { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
          this.setState({
              isSpendCategoryLoaded: true,
              spendCategory: this.createSpendCategoryList(json)
          })
        // console.log(json)
      });

}


createSpendCategoryList = (value) => {
  var tempList = []
  value.map(e => {
      e.value = e.referenceIDKey
      e.label = e.spendCategoryDesc
      tempList.push(e)
  })

  return tempList
}

// getSupplierEntity = () => {
//   let myURI = apiURL + 'AP/SupplierRequest/Entity'

//   fetch(myURI, { modes: 'no-cors' })
//       .then(res => res.json())
//       .then(json => {
//           this.setState({
//               isEntityLoaded: true,
//               entity: this.createSupplierEntityList(json)
//           })
//           // console.log(json)
//       });
// }

// getSupplierEntity = () => {
//   let myURI = apiURL + 'ItemMaster/WDCompany/ActiveOnly'

//   fetch(myURI, { modes: 'no-cors' })
//       .then(res => res.json())
//       .then(json => {
//           this.setState({
//               isEntityLoaded: true,
//               entity: this.createSupplierEntityList(json)
//           })
//           // console.log(json)
//       });
// }


// createSupplierEntityList = (value) => {
//   var tempList = []
//   value.map(e => {
//       e.value = e.supplierReqEntityKey
//       e.label = e.supplierReqEntityDesc
//       tempList.push(e)
//   })
//   return tempList
// }

createSupplierEntityList = (value) => {
  var tempList = []
  value.map(e => {
      e.value = e.referenceIDKey
      e.label = e.companyDesc
      tempList.push(e)
  })
  return tempList
}


getSupplierEntity = () => {
  let myURI = apiURL + 'SupplyChain/PORequest/Filtered/Company'

  fetch(myURI, { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
          this.setState({
              isEntityLoaded: true,
              entity: this.createSupplierEntityList(json)
          })
          //  console.log(json)
      });
}
getStatus = () => {
  let myURI = apiURL + 'SupplyChain/PurchaseOrderRequest/Status'

  fetch(myURI, { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
          this.setState({
              isStatusLoaded: true,
              status: this.createStatusList(json)
          })
          // console.log(json)
      });
}

createStatusList = (value) => {
  var tempList = []
  value.map(e => {
      e.value = e.poRequestStatusKey
      e.label = e.poRequestStatusDesc
      tempList.push(e)
  })
  return tempList
}




getSupplier = () => {
  let myURI = apiURL + 'ItemMaster/Supplier/ActiveOnly';

  fetch(myURI, { modes: 'no-cors' })
    .then((res) => res.json())
    .then((json) => {
      this.setState({
        isSupplierLoaded: true,
        supplier: this.createSupplierList(json),
      });
    })
    .catch((error) => {
      console.error("Failed to fetch supplier data:", error);
      this.setState({ isSupplierLoaded: false });  // Add error handling or fallback state
    });
}

// createSupplierList = (value) => {
//   var tempList = []
//   value.map(e => {
//       e.value = e.wdSupplierKey
//       e.label = e.alternate_Name
//       tempList.push(e)
//   })
//   return tempList
// }

createSupplierList = (value) => {
  var tempList = []
  value.map(e => {
      e.value = e.wdSupplierKey
      e.label = e.supplier_Name + '-' + e.supplier_ID 
      tempList.push(e)
  })
  return tempList
}

getSite= () => {
  let myURI = apiURL + 'Supplychain/PORequest/Site'

  fetch(myURI, { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
          this.setState({
              isSiteLoaded: true,
              siteData: this.createSiteList(json),
              siteDataDefault: json
          })
          // console.log("Site: " + JSON.stringify(json, null, 2));
      });
}

createSiteList = (value) => {
  var tempList = []
  value.map(e => {
      e.value = e.siteID
      e.label = e.siteDesc
      tempList.push(e)
  })
  return tempList
}



getAFE = () => {
  let myURI = apiURL + 'SupplyChain/PORequest/AFE'

  // console.log(myURI);

  fetch(myURI, { modes: 'no-cors' })
      .then(res => res.json())
      .then(json => {
          this.setState({
              afeData: this.createAFEList(json),
              afeDataDefault: json,
              isAFELoaded: true
          })
          // console.log(json)
      });
}


createAFEList = (value) => {
  var tempList = []
  value.map(e => {
      e.value = e.project_ID
      e.label = e.projectDesc
      tempList.push(e)
  })

  return tempList
}



togglePODetails = () => {
  this.setState({
      modalPODetails: !this.state.modalPODetails
  })
}


closeForm=()=>{
  this.setState({
    modalPODetails: !this.state.modalPODetails,
    // poRequestKey: -1,
    openPODetails: false,
})
// this.getPOReqData()
}

newPORequest = () => {
  this.setState({
      poRequestKey: -1,
  })
  this.togglePODetails()

}


onTextChange = (fieldName, fieldValue, e) => {
  const { fullPOReqData } = this.state;

  switch (fieldName) {
    case "mocSearch":
      this.setState({ searchText: fieldValue.toLowerCase() });
      break;

    case "poRequestQuery":
      this.getFilterPO(
        fullPOReqData,
        e,
        this.state.selEntity,
        this.state.selStatus
      );
      this.setState({ selPORequestQuery: e });
      break;

    case "entity":
      this.getFilterPO(
        fullPOReqData,
        this.state.selPORequestQuery,
        e,
        this.state.selStatus
      );

      this.setState({ selEntity: e });

      break;

    case "status":
      this.getFilterPO(
        fullPOReqData,
        this.state.selPORequestQuery,
        this.state.selEntity,
        e
      );
      this.setState({ selStatus: e });
      break;

    default:
      break;
  }
};


getDefaultPOList = (e) => {
  this.getFilterPO(
    e,
    this.state.selPORequestQuery,
    this.state.selEntity,
    this.state.selStatus
  );
};

// setDefaultPOQuery = () => {
//   this.setState({ selPORequestQuery: this.state.poRequestQueryList[0] });
// };



getFilterPO = (e, m, b, s) => {
  const { user } = this.props;
  const {isSecurityAdminLogin} = this.state

  const basins = b.map((e) => e.supplierReqEntityDesc).join(",");
  const status = s.map((e) => e.poRequestStatusDesc).join(",");
// console.log("m.value: " + m.value)
  if (m !== null) {
    const userName = getUserADAccount(user.account.userName).toLowerCase();
    var tempPORequest = e;
    // console.log(m.value)
    if (m.value === 1) {
      tempPORequest = tempPORequest.filter(
        (e) =>(e.createdBy || "").toLowerCase() === userName    
      );
      // console.log(tempPORequest)
    }
  
  }


  if (basins.length > 0) {
    tempPORequest = tempPORequest.filter((e) =>
      basins.includes(e.supplierReqEntityDesc)
    );

  }
  if (status.length > 0) {
    tempPORequest = tempPORequest.filter((e) =>status === e.poRequestStatusDesc);

  }

  this.setState({ poReqData: tempPORequest });

  s.map((e) => {
    if (
      e.poRequestStatusDesc === "Canceled" ||
      e.poRequestStatusDesc === "Completed"  ||
      e.poRequestStatusDesc === "Request Complete - Pending Approval WD"
    ) {
      this.getPORequestDataByStatus(e.poRequestStatusDesc).then((rtnAFE) => {
        tempPORequest = [...tempPORequest, ...rtnAFE];
        this.setState({ poReqData: tempPORequest });
      });
      //
    }
  });

};


getPORequestDataByStatus = (PORequestStatus) => {
  let myURI =
    apiURL +
    "SupplyChain/PurchaseOrderRequest/startDate/" +
    this.state.startDate.toString().substring(0, 10) +
    "/endDate/" +
    this.state.endDate.toString().substring(0, 10) +
    "/PORequestStatus/" +
    PORequestStatus;

  return fetch(myURI, { modes: "no-cors" }).then((res) => res.json());
};

formatDate = (value) => {
  if (!value) {
      // console.error('The value passed to formatDate is falsy:', value);
      return '';
  }
  
  // Adjust the date to Central Time by subtracting the UTC offset
  const ctOffsetHours = this.isDaylightSavingTime(moment(value)) ? -5 : -6;
  const ctDate = moment(value).add(ctOffsetHours, 'hours');
  
  return ctDate.format('M/D/YYYY h:mm A');
}

// Basic check for DST; might not be accurate for all scenarios
isDaylightSavingTime = (date) => {
  const start = moment(date.year() + "-03-08"); // Second Sunday of March
  const end = moment(date.year() + "-11-01");   // First Sunday of November
  return date.isBetween(start, end);
}

formatString=(value)=>{
  if (!value) {
    // console.error('The value passed to formattedString is falsy:', value);
    return '';
}
const formattedString = value.replace(/\./g, ' ');

return formattedString
}

saveGridLayout = () => {
  if (this.gridRef.current) {
    // Extract only necessary properties to avoid circular references
    let layoutData = {
      columns: this.gridRef.current.getColumns().map((col, index) => ({
        field: col.field,
        headerText: col.headerText,
        width: col.width,
        visible: col.visible,
        index: index, // Include index for ordering
      })),
      sortSettings: this.gridRef.current.sortSettings.sortedColumns || [], // Extract sorted columns only
      filterSettings: this.gridRef.current.filterSettings.columns || [], // Extract filtered columns only
      groupSettings: this.gridRef.current.groupSettings.columns || [], // Extract grouped columns only
      pageSettings: {
        pageSize: this.gridRef.current.pageSettings.pageSize,
        currentPage: this.gridRef.current.pageSettings.currentPage,
      },
    };

    const addLayoutDTO = {
      applicationKey: 33,
      userName: this.props.user.account.userName,
      layoutName: "SupplierPORequest",
      layoutData: JSON.stringify(layoutData),  // Serialize for storage
      createdBy: this.props.user.account.userName,
    };

    const myURI = `${apiURL}AppLayout/AddLayout`;
    fetch(myURI, apiPOSTHeader(addLayoutDTO))
      .then(async (response) => {
        if (!response.ok) {
          console.error("Error saving layout");
        } else {
          // console.log("Layout saved successfully");
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  } else {
    console.error("Grid reference is undefined.");
  }
};



loadGridLayout = () => {
  const { user } = this.props;
  if (this.gridRef.current) {
    const myURI = `${apiURL}AppLayout/Grid/33/${user.account.userName}/SupplierPORequest`;

    fetch(myURI, apiHeader('GET'), { modes: 'no-cors' })
      .then((res) => res.json())
      .then((json) => {
        if (json.length > 0) {
          const layoutData = JSON.parse(json[0].layoutData);

          // Define headerText for each field
          const headerTextMap = {
            poReqID: "Req #",
            poRequestStatusDesc: "Status",
            companyDesc: "Entity",
            projectNoName: "AFE",
            workdayPOID: "Workday PO #",
            supplier_Name: "Supplier Name",
            spendCategoryDesc: "Spend Category",
            emailAddress: "Email",
            comments: "Comments",
            createdBy: "Created By",
            modifiedBy: "Modified By",
            createdDatetime: "Requested Date",
            modifiedDateTime: "Modified Date",
          };

          // Sort columns by their saved 'index' property and add header texts
          const sortedColumns = layoutData.columns
            .map((column) => ({
              ...column,
              headerText: headerTextMap[column.field] || column.headerText,
            }))
            .sort((a, b) => a.index - b.index); // Sort columns by 'index'

          // Update state with sorted columns and other settings
          this.setState({
            sortedColumns, // Store sorted columns in state
            sortSettings: layoutData.sortSettings,
            filterSettings: layoutData.filterSettings,
            groupSettings: layoutData.groupSettings,
            pageSettings: layoutData.pageSettings,
            isGridLoaded: true,
          });
        } else {
          console.warn("No layout data found.");
        }
      })
      .catch((error) => {
        console.error("Error loading layout:", error);
      });
  } else {
    console.error("Grid reference is undefined.");
  }
};









onGridDataBound = () => {
  // Ensure grid layout loads only once to avoid redundant calls
  if (!this.state.isGridLoaded) {
    this.loadGridLayout();
    this.setState({ isGridLoaded: true });
  }
};

  render() {
    const {pageSize, poReqData, isPOReqLoaded, poRequestKey, gridRef, entity, 
      isEntityLoaded, afeData, isAFELoaded, offLineLinkKey, poReqID, isSiteLoaded, 
      siteData, isSpendCategoryLoaded, spendCategory, isSupplierLoaded, supplier, 
      poRequestQueryList, selPORequestQuery, selEntity, isStatusLoaded, status, isSecurityLoaded, searchText, isSecurityAdminLogin, toolbar, formatOption, afeDataDefault, siteDataDefault, user } = this.state
      const gridHeight = 0.6 * this.state.windowHeight;

    if (!isPOReqLoaded, !isEntityLoaded, !isAFELoaded, !isSiteLoaded, !isSpendCategoryLoaded, !isSupplierLoaded, !isStatusLoaded, !isSecurityLoaded) {
      return (<div>Loading PO data.....</div>)
  }
  else
    return (
      <div>
        <FormGroup>
          <Row form className="d-flex align-items-center">
            <h2>PO Request | </h2>
            <Button
              outline
              color="secondary"
              className="btn-no-border"
              onClick={this.refreshDatafilter}
            >
              <img
                src={Refresh}
                alt="Refresh"
                style={{ width: "30px", margin: "0 5px 0 0" }}
              />
              Refresh
            </Button>
            <Button
              outline
              color="secondary"
              className="btn-no-border"
              onClick={this.newPORequest}
              //   disabled={supplierRequestKey < 0}
            >
              <img
                src={Plus}
                alt="new"
                style={{ width: "25px", margin: "0 5px 0 0" }}
              />
              New
            </Button>{" "}
            <Button
              outline
              color="secondary"
              className="btn-no-border"
              disabled={poRequestKey < 0}
              onClick={(e) => this.togglePODetails()}
            >
              <img
                src={Revise}
                alt="new"
                style={{ width: "25px", margin: "0 5px 0 0" }}               
              />
              View Details
            </Button>{" "}

{/* 
            <div className="d-flex align-items-center ml-3">
              <Label
                className="mb-0 mr-2 flex-shrink-0"
                style={{ padding: "0", whiteSpace: "nowrap" }}
              >
                Start Date
              </Label>
              <DatePicker
                value={this.state.startDate}
                onChange={(v, f) => this.handleChange(v, f)}
              />
            </div>
            <div className="d-flex align-items-center ml-3">
              <Label
                className="mb-0 mr-2 flex-shrink-0"
                style={{ padding: "0", whiteSpace: "nowrap" }}
              >
                End Date
              </Label>
              <DatePicker
                value={this.state.endDate}
                onChange={(v, f) => this.handleEndDateChange(v, f)}
              />
            </div> */}
          </Row>
          <ColoredLine color="grey" />
        </FormGroup>

        <FormGroup>
          <Row form>
            <Col md={2}>
              <Row form>
                <Col md={12}>
                  <Label>Filter</Label>

                  <div>
                    <Select
                      className="alway-in-between-more"
                       options={poRequestQueryList}
                         value={selPORequestQuery}
                      // defaultValue={selMOCQuery}
                      onChange={(e) => this.onTextChange("poRequestQuery", e, e)}
                    ></Select>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={4}>
              <Row form>
                <Col md={12}>
                  <Label>Entity</Label>

                  <div>
                    <Select
                       options={entity}
                       isMulti
                       value={selEntity}
                      // defaultValue={selBasin}
                      onChange={(e) => this.onTextChange("entity", e, e)}
                      isClearable={true}
                    ></Select>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={4}>
              <Row form>
                <Col md={12}>
                  <Label>Status</Label>

                  <div>
                    <Select
                      className="alway-in-between-more"
                       options={status}
                      // value={selStatus}
                      // defaultValue={selStatus}
                      isClearable={true}
                      isMulti
                      onChange={(e) => this.onTextChange("status", e, e)}
                    ></Select>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={2}>
              <Row form>
                <Col md={12}>
                  <div>
                    <Label for="typeKey"> </Label>
                    <Input
                      width="100%"
                      className="floatRight"
                      placeholder="Type here to search......."
                      onChange={(e) =>
                        this.onTextChange("mocSearch", e.target.value, e)
                      }
                    ></Input>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          
          <GridComponent
            // className="my-custom-grid"
            dataSource={poReqData.filter((rec) => (rec.poReqID || "").toLowerCase().includes(searchText)
                                                  ||(rec.poRequestStatusDesc || "").toLowerCase().includes(searchText)
                                                  ||(rec.supplierReqEntityDesc || "").toLowerCase().includes(searchText)
                                                  ||(rec.afeid || "").toLowerCase().includes(searchText)
                                                  ||(rec.workdayPOID || "").toLowerCase().includes(searchText) 
                                                  ||(rec.siteDesc || "").toLowerCase().includes(searchText)
                                                  ||(rec.supplier_Name || "").toLowerCase().includes(searchText)
                                                  ||(rec.spendCategoryDesc || "").toLowerCase().includes(searchText) 
                                                  ||(rec.emailAddress || "").toLowerCase().includes(searchText) 
                                                  ||(rec.comments || "").toLowerCase().includes(searchText) 
                                                  ||(rec.createdBy || "").toLowerCase().includes(searchText)
                                                  
                                                  )}
            id='grid'
            rowSelected={this.rowSelected}
            ref={this.gridRef}
            height={`${gridHeight}px`}
            rowDataBound={this.rowDataBound}
            allowResizing={true}
            allowFiltering={true}
            toolbar={toolbar}
            toolbarClick={this.toolbarClick}
            allowExcelExport={true}   
            allowReordering={true}
            filterSettings={{
              type: "Menu",
            }}
            dataBound={this.onGridDataBound}
          >
          <ColumnsDirective>
            {this.state.sortedColumns && this.state.sortedColumns.length > 0
              ? this.state.sortedColumns.map((col, index) => (
                  <ColumnDirective
                    key={index}
                    field={col.field}
                    headerText={col.headerText}
                    width={col.width}
                    visible={col.visible}
                    autoFit={col.autoFit || false}
                    template={col.field === 'createdBy' || col.field === 'modifiedBy'
                      ? (row) => <span>{this.formatString(row[col.field])}</span>
                      : col.field === 'createdDatetime' || col.field === 'modifiedDateTime'
                      ? (row) => <span>{this.formatDate(row[col.field])}</span>
                      : undefined}
                  />
                ))
              : null}
          </ColumnsDirective>
            <Inject services={[Sort, Filter, Group, Resize, ExcelExport, Toolbar, Reorder ]} />
          </GridComponent>



        </FormGroup>

        <div>
          <Modal
            isOpen={this.state.modalPODetails}
            toggle={() => this.togglePODetails()}
            size={poRequestKey < 0 ? "med" : "xl"}
            backdrop={"static"}
            // className="custom-modal2"
          >
            <div
            // className="modal-content" style={{ backgroundColor: "#F6F5F5" }}
            >
              <ModalHeader 
               toggle={() => this.closeForm()}
              >
                {poRequestKey < 0 ? "New PO" : "ReqNo. " + poReqID}
              </ModalHeader>
              <ModalBody>
                <PORequestDetails
                  toggle={this.closeForm}
                  user={this.props.user}
                  poRequestKey={poRequestKey}
                  entity={entity}
                  afeData={afeData}
                  afeDataDefault={afeDataDefault}
                  siteData={siteData}
                  siteDataDefault={siteDataDefault}
                  offLineLinkKey={offLineLinkKey}
                  refreshData={this.getPOReqData}
                  spendCategory={spendCategory}
                  supplier={supplier}
                  isSecurityAdminLogin = {isSecurityAdminLogin}
                  isSupplierLoaded={isSupplierLoaded}
                ></PORequestDetails>
              </ModalBody>
            </div>
          </Modal>
        </div>
      </div>
    );
  }

  rowDataBound(args) {
    let status = args.data.poRequestStatusDesc;

    if (status === "Complete") {
        args.row.style.backgroundColor = "#A2E09D";
    } else if (status === "Canceled") {
        args.row.style.backgroundColor = "#f88379";
    } else if (status === "Rejected") {
        args.row.style.backgroundColor = "#f88379";
    } else if (status === "Active") {
        args.row.style.backgroundColor = "#75ebe9";
    } else if (status === "Pending") {
        args.row.style.backgroundColor = "#f0f5b0";
    }
 
    else if (status === "Request Complete - Pending Approval WD") {
      args.row.style.backgroundColor = "#A2E09D";
  }
    else {
        args.row.style.backgroundColor = "transparent";
    }
}


}


