import React, { Component } from 'react';
import { Col, Row, Button, FormGroup, Label, Input, CustomInput, Form, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import { apiURL, apiHeader, apiPUTHeader, apiPOSTHeader, apiDELETEHeader } from '../../resources/apiURL';
import ConfirmModal from "../../operation/ConfirmModal";
import DocumentBrowserDragDrop from '../../resources/DocumentBrowserDragDrop';
import { TabContent, TabPane, Nav, NavItem, NavLink, FormFeedback, Alert, InputGroupAddon } from 'reactstrap';
import classnames from 'classnames';
import Select from 'react-select';
import Creatable from 'react-select/creatable'
import { getUserADAccount, getRandomInt, ColoredLine } from "../../resources/utility";
import TrashBin from "../../resources/trash.svg";
import moment from "moment";
import NumberFormat from 'react-number-format';
// import "../../../node_modules/@syncfusion/ej2-react-inputs/styles/bootstrap.css";
// import "../../../node_modules/@syncfusion/ej2-react-dropdowns/styles/bootstrap.css";
export default class PORequestDetails extends Component {
  static displayName = PORequestDetails.name;
  constructor(props) {
    super(props);
    const urlPara = this.getURLParameters(props.location);
    this.state = {
      user: props.user,
      poRequestKey: urlPara.poRequestKey === null ? props.poRequestKey : urlPara.poRequestKey,
      poRequestData: [],
      isPORequestDataLoaded: false,
      entity: props.entity,
      entityDropDownRef: React.createRef(),
      supplierCatDropDownRef: React.createRef(),
      afeData: props.afeData,
      afeDataDefault: props.afeDataDefault,
      afeDropDownRef: React.createRef(),
      offLineLinkKey: props.offLineLinkKey,
      activeTab: "1",
      siteData: props.siteData,
      siteDataDefault: props.siteDataDefault,
      spendCategory: props.spendCategory,
      supplier: props.supplier,
      isSupplierLoaded: props.isSupplierLoaded,
      hiddenOnly: false,
      offLineLinkKey: (getRandomInt(10000) * -1),
      isSecurityAdminLogin: props.isSecurityAdminLogin,
      msgHeader: "",
      msgBody: "",
      modalMsg: false,
      validDataItem: {
        entity: true,
        spendCategory: true,
        supplierID: true,
        emailAddress: true,
        amount: true,
        comments: true
      }
    };
  }

  getURLParameters = (inputPara) => {
    var paraMeters = {
      poRequestKey: null,
    };
    var query;
    if (inputPara !== undefined) {
      query = new URLSearchParams(inputPara.search);

      paraMeters = {
        poRequestKey: query.get("key"),
      };
    }

    return paraMeters;
  };


  dataValidation = () => {
    return new Promise((resolve, reject) => {
        const returnVal = { "ok": true, "message": "", "headerValidation": [] }
        const {poRequestData, validDataItem } = this.state

      
        if (poRequestData[0].entity === null || poRequestData[0].entity === "") {
          validDataItem.entity = false
          returnVal.ok = false
          returnVal.message = "Please enter missing data in red"
      }

      if (poRequestData[0].spendCategory === null || poRequestData[0].spendCategory === "") {
        validDataItem.spendCategory = false
        returnVal.ok = false
        returnVal.message = "Please enter missing data in red"
    }


      if (poRequestData[0].supplierID === null || poRequestData[0].supplierID === "") {
        validDataItem.supplierID = false
        returnVal.ok = false
        returnVal.message = "Please enter missing data in red"
    }

    if (poRequestData[0].emailAddress === null || poRequestData[0].emailAddress === "") {
      validDataItem.emailAddress = false
      returnVal.ok = false
      returnVal.message = "Please enter missing data in red"
  }

  if (poRequestData[0].amount < 0.00 || poRequestData[0].amount === 0.00) {
    validDataItem.amount = false;
    returnVal.ok = false;
    returnVal.message = "Please enter missing data in red";
  }

  
  if (poRequestData[0].comments === null || poRequestData[0].comments === "") {
    validDataItem.comments = false;
    returnVal.ok = false;
    returnVal.message = "Please enter missing data in red";
  }

        returnVal.headerValidation = validDataItem

        resolve(returnVal)
    })
}

  componentDidMount() {
    this.getPORequest();
  }

  componentDidUpdate(prevProps) {
    if (!this.state.isPORequestDataLoaded && this.props.supplier.length > 0) {
      this.setState({ isPORequestDataLoaded: true });
    }

    // Only update state if the supplier prop has changed
    if (prevProps.supplier !== this.props.supplier) {
      this.setState({ supplier: this.props.supplier });
    }
}

  getPORequest = () => {
    const { poRequestKey } = this.state;
    if (poRequestKey > 0) {
      let myURI = apiURL + "Supplychain/PurchaseOrderRequest/" + poRequestKey;
      fetch(myURI, apiHeader("GET"), { modes: "no-cors" })
        .then((res) => res.json())
        .then((json) => {
          this.setState({
            isPORequestDataLoaded: true,
            poRequestData: json,
          });
          // console.log(json);
        });
    } else {
      const newPORequest = this.createPORequest()
      this.setState({
          isPORequestDataLoaded: true,
          poRequestData: newPORequest,
          readOnly: false,
          hiddenOnly: true
      })
    }
  };


  createPORequest = () => {
    const { user } = this.props
    const newWORecord = ({
        "poRequestKey": -1,
        "woNumber": 'WOXXXXX',
        "statusKey": 0,
        "afeid": "",
        "workdayPOID": null,
        "entity": null,
        "siteID": "",
        "supplierID": "",
        "spendCategory": "",
        "emailAddress": "",
        "amount": 0.00,
        "comments": "",
        "createdBy": user.account.name,
       
    })
 
// console.log(newWORecord)
    return [newWORecord]

}

getPOLines = (supplierID) => {
  let myURI = `${apiURL}Supplychain/PORequest/POLine/Supplier/${supplierID}`;

  fetch(myURI, apiHeader("GET"))
    .then((res) => {
      if (!res.ok) {
        // throw new Error("Failed to fetch spend category");
      }
      return res.json();
    })
    .then((json) => {
      // console.log("Full API Response Object:", json); // Debug entire response

      // Fix case sensitivity issue
      const spendCategoryID = json?.SpendCategoryID || json?.spendCategoryID;

      // console.log("Extracted SpendCategoryID:", spendCategoryID);

      if (spendCategoryID) {  
        this.setState((prevState) => {
          let updatedPORequestData = [...prevState.poRequestData];

          updatedPORequestData[0] = {
            ...updatedPORequestData[0],
            spendCategory: spendCategoryID.trim()
          };

          // console.log("Updating spendCategory with:", spendCategoryID); 
          return { poRequestData: updatedPORequestData };
        }, () => {
          // console.log("Updated PO Data after state update:", this.state.poRequestData);
        });
      } else {
      }
    })
    .catch((error) => {
    });
};










  clearDropdown = (dropdownRef) => {
    if (dropdownRef.current) {
      dropdownRef.current.clear(); // Call the clear method on the ref
    }
  };

  toggleTab = (tab) => {
    const { activeTab } = this.state;

    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };



   getEntityString = (value) => {
    var grpKey = ''
    value.map(e => {
        if (grpKey !== "") {
            grpKey = grpKey + ','
        }

        grpKey = grpKey + e.value
    })

    return grpKey;
}

getAFETypeCostString = (value) => {
        var grpKey = ''
        value.map(e => {
            if (grpKey !== "") {
                grpKey = grpKey + ','
            }

            grpKey = grpKey + e.afeLineTypeKey
        })

        return grpKey;
    }


    

  onTextChange = (fieldName, fieldValue, e) => {
    const{afeDataDefault,  siteDataDefault} = this.state
    // console.log(fieldName + '=' + fieldValue)

    const { poRequestData, validDataItem } = this.state;

    switch (fieldName) {
      case "entity":
        // poRequestData[0].entity = e === null ? "" : e.supplierReqEntityKey;

        poRequestData[0].entity = e === null ? "" : e.referenceIDKey;
        validDataItem.entity = true
        if (e !== null && e !== "" )
          {
            // console.log("This hit")
            // console.log(e.companyID)
            this.filterAFE(e.referenceIDKey)
            this.filterSite(e.companyID)
            poRequestData[0].afeid = null
            poRequestData[0].siteID = null
          }
          else{
            this.setState({afeData: afeDataDefault, siteData: siteDataDefault})
          }
        
        break;

      case "spendCategory":
        poRequestData[0].spendCategory = this.getEntityString(e);
        validDataItem.spendCategory = true
        break;

      case "afeid":
        poRequestData[0].afeid = e === null ? "" : e.project_ID;
        poRequestData[0].siteID = e === null ? "" : e.siteID;
        break;

      case "supplierID":
        poRequestData[0].spendCategory = "";
        poRequestData[0].supplierID = e === null ? "" : e.supplier_ID === undefined ? e.value : e.supplier_ID;
        // console.log(e.value)
        // poRequestData[0].supplierID = e === null ? "" : e.value;
        // poRequestData[0].emailAddress = e === null ? "" : e.primary_Email_Address == null ? "" : e.primary_Email_Address
        if (poRequestData[0].supplierID) {
          this.getPOLines(poRequestData[0].supplierID);
        }
        validDataItem.supplierID = true
        // validDataItem.emailAddress = true
        break;

      case "emailAddress":
        poRequestData[0].emailAddress = fieldValue;
        validDataItem.emailAddress = true
        break;

      case "siteID":
        // poRequestData[0].siteID = e === null ? "" : e.siteID;
        poRequestData[0].siteID = e === null ? "" : e.siteID == undefined ? e.value : e.siteID;
        break;

      case "amount":
        poRequestData[0].amount = fieldValue;
        validDataItem.amount = true
        break;

      case "comments":
        poRequestData[0].comments = fieldValue;
        validDataItem.comments = true
        break;

      case "adminComments":
        poRequestData[0].adminComments = fieldValue;
        break;

      case "workdayPOID":
        poRequestData[0].workdayPOID = fieldValue;
        break;

      default:
    }

    this.setState({poRequestData : poRequestData, validDataItem: validDataItem});
  };

  // getSelEntity = (v) => {
  //   if (!v) return [];
    
  //   const selectedEntityKeys = v.split(',').map(item => item.trim());
    
  //   // Return only the values (not the whole objects)
  //   return selectedEntityKeys.map(key => {
  //     const entityItem = this.state.entity.find(e => e.supplierReqEntityDesc === key);
  //     return entityItem ? entityItem.supplierReqEntityDesc : null;
  //   }).filter(Boolean);
  // }

//    getSelEntity = (v) => {
//     const {entity} = this.state
//     console.log(typeof v)
//     if (v !== null) {
//         return entity.filter(e => v.includes(e.supplierReqEntityKey))
//     }
//     return ""
// }

// getSelEntity = (v) => {
//   const {entity} = this.state;

//   if (v !== null) {
//       if (typeof v === 'object' && v.supplierReqEntityKey !== undefined) {
//           return entity.filter(e => e.supplierReqEntityKey === v.supplierReqEntityKey);
//       } else if (typeof v === 'number') {
//           return entity.filter(e => e.supplierReqEntityKey === v);
//       }
//   }
//   return "";
// }

getSelEntity = (v) => {
  const {entity} = this.state;

  if (v !== null) {
      if (typeof v === 'object' && v.referenceIDKey !== undefined) {
          return entity.filter(e => e.referenceIDKey === v.referenceIDKey);
      } else if (typeof v === 'number') {
          return entity.filter(e => e.referenceIDKey === v);
      }
  }
  return "";
}

getSelSupplier = (v) => {
  const { supplier } = this.state;
  //  console.log(v)
  if (v !== null) {
      const filteredSuppliers = supplier.filter(e => e.supplier_ID === v);
      
      // If no records match, return v in the required format
      if (filteredSuppliers.length === 0) {

        // console.log({ label: v, value: v })
        // console.log(v)
          return { label: v, value: v };
      }
      // console.log("Skipped filter")
      // Assuming each supplier in the supplier array has a label and value property
      return filteredSuppliers[0];  // return the first matched supplier
  }

  return null;
  // return { value: 1, label: v }
}

getSelSpendCat = (v) => {
  const { spendCategory } = this.state;

  if (v !== null && v !== "" && v !== undefined) {
    let selectedKeys = v.split(',').map(id => id.trim());

    // First attempt to filter by referenceIDKey
    let filteredCategories = spendCategory.filter(e => selectedKeys.includes(e.referenceIDKey?.toString()));
    // console.log("filteredCategories: " + filteredCategories)
    // If no match is found, filter by SpendCategoryID
    if (filteredCategories.length === 0) {
      filteredCategories = spendCategory.filter(e => selectedKeys.includes(e.spendCategoryID?.toString()));
      // console.log("filteredCategoriesNew: " + filteredCategories)
    }

    return filteredCategories;
  }
  
  return "";
};


getSelAFE = (v) => {
  const { afeData } = this.state
  if (v !== null && v !== "") {
    // console.log(v)
      return afeData.filter(e => e.project_ID === v)
  }
  else {
      return null
  }

}

getSelSite = (v) => {
  const { siteData } = this.state
  // if (v !== null && v !== "") {
  //     return siteData.filter(e => e.siteID === v)
  // }
  // else {
  //     return null
  // }
  // // return { value: 1, label: v }

  if (v !== null) {
    const filteredSite = siteData.filter(e => e.siteID === v);
    
    // If no records match, return v in the required format
    if (filteredSite.length === 0) {

      // console.log({ label: v, value: v })
        return { label: v, value: v };
    }
    
    // Assuming each supplier in the supplier array has a label and value property
    return filteredSite[0];  // return the first matched supplier
}

}


updatePORequestDTO = () => {
  const { poRequestData } = this.state;
  const { user } = this.props;
  const poRequestUpdateDTO = {
    poRequestKey: poRequestData[0].poRequestKey,
    statusKey: poRequestData[0].statusKey,
    afeid: poRequestData[0].afeid,
    workdayPOID: poRequestData[0].workdayPOID,
    entity: poRequestData[0].entity,
    siteID: poRequestData[0].siteID,
    supplierID: poRequestData[0].supplierID,
    spendCategory: poRequestData[0].spendCategory,
    emailAddress: poRequestData[0].emailAddress,
    amount: poRequestData[0].amount,
    comments: poRequestData[0].comments,
    adminComments: poRequestData[0].adminComments,
    modifiedBy: getUserADAccount(user.account.userName),

  };
  return poRequestUpdateDTO;
};


updatePORequest = (originalStatusKey) => {
  const {poRequestData} = this.state
  const updatePORequestDTO = this.updatePORequestDTO();
  let myURI = apiURL + "SupplyChain/PurchaseOrderRequest";
  fetch(myURI, apiPUTHeader(updatePORequestDTO))
    .then(async (response) => {
      const rspData = response.text();
      if (!response.ok) {
        const error = (rspData && rspData.message) || response.status;
        rspData.then((t) => {
          this.setState({
            msgBody: t,
            msgHeader: "Error",
            modalMsg: !this.state.modalMsg,
            poRequestData: [
              {
                ...poRequestData[0],
                statusKey: originalStatusKey,
              },
            ],
          });
        });
        return Promise.reject(error);
      }

      this.closeForm()
  
    })
    .catch((error) => {
      // this.closeForm()
    });
};



addPORequest=(originalStatusKey)=>{
  const {poRequestData} = this.state
  const poRequestCreateDTO = this.createPORequestDTO()
        let myURI = apiURL + 'SupplyChain/PurchaseOrderRequest'
        fetch(myURI, apiPOSTHeader(poRequestCreateDTO))
            .then(async response => {
                const rspData = response.text();
                if (!response.ok) {

                    const error = (rspData && rspData.message) || response.status;
                    rspData.then(t => {           
                        this.setState({
                            msgBody: t,
                            msgHeader: "Error",
                            modalMsg: !this.state.modalMsg,
                            poRequestData: [
                              {
                                ...poRequestData[0],
                                statusKey: originalStatusKey,
                              },
                            ],
                        })
                    })
                    return Promise.reject(error);

                }
                this.closeForm()
               

            })
            .catch(error => {

            })
}


createPORequestDTO = () => {
  const { poRequestData, user, offLineLinkKey } = this.state
  const poRequestCreateDTO = {
      "statusKey": poRequestData[0].statusKey,
      "afeid": poRequestData[0].afeid,
      "entity": poRequestData[0].entity,
      "siteID": poRequestData[0].siteID,
      "supplierID": poRequestData[0].supplierID,
      "spendCategory": poRequestData[0].spendCategory,
      "emailAddress": poRequestData[0].emailAddress,
      "amount": poRequestData[0].amount,
      "comments": poRequestData[0].comments,
      "adminComments": poRequestData[0].adminComments,
      "createdBy": getUserADAccount(user.account.userName),
      "offLineLinkKey": offLineLinkKey
      
  }
  //  console.log(poRequestCreateDTO)
  return poRequestCreateDTO;
}


deletePORequest = () => {
  const poRequestDeleteDTO = this.createPORequestDeleteDTO();

  let myURI = apiURL + "SupplyChain/PurchaseOrderRequest";
  fetch(myURI, apiDELETEHeader(poRequestDeleteDTO))
    .then(async (response) => {
      const rspData = response.text();
      if (!response.ok) {
        const error = (rspData && rspData.message) || response.status;
        return Promise.reject(error);
      }
      this.closeForm();
    })
    .catch((error) => {});
};

createPORequestDeleteDTO = () => {
  const { poRequestKey } = this.state;

  const poRequestDeleteDTO = {
    poRequestKey: poRequestKey,
    deletedBy: "",
  };
  return poRequestDeleteDTO;
};


saveData = (originalStatusKey) => {
  const { poRequestData } = this.state;
  if (poRequestData[0].statusKey === 0)
  {
    if (poRequestData[0].poRequestKey > 0) {
      this.updatePORequest(originalStatusKey);
    } else {
      this.addPORequest(originalStatusKey);
    }
  }
  else{
    this.dataValidation().then((data) => {
      if (data.ok) {
        if (poRequestData[0].poRequestKey > 0) {
          this.updatePORequest(originalStatusKey);
        } else {
          this.addPORequest(originalStatusKey);
        }
      } else {
        this.setState({
          alertMessage: data.message,
          validDataItem: data.headerValidation,
          showAlert: true,
        });
      }
    });
  }

      
};


closeForm=()=>{
  this.props.toggle()
  this.props.refreshData()
}

submitPO = () => {
  const { poRequestData } = this.state;
  const originalStatusKey = poRequestData[0].statusKey;
  this.dataValidation().then((data) => {
    if (data.ok) {
  poRequestData[0].statusKey = 3;
  this.setState({ poRequestData: poRequestData });

  this.saveData(originalStatusKey);
    }
    else {
      this.setState({
        alertMessage: data.message,
        validDataItem: data.headerValidation,
        showAlert: true,
      });
    }
  })

};

acceptPO = () => {
  const { poRequestData } = this.state;
  const originalStatusKey = poRequestData[0].statusKey;
  poRequestData[0].statusKey = 4;
  this.setState({ poRequestData: poRequestData });

  this.saveData(originalStatusKey);

};


rejectPO = () => {
  const { poRequestData } = this.state;
  const originalStatusKey = poRequestData[0].statusKey;
  poRequestData[0].statusKey = 1;
  this.setState({ poRequestData: poRequestData });

  this.saveData(originalStatusKey);

};

cancelPO = () => {
  const { poRequestData } = this.state;
  const originalStatusKey = poRequestData[0].statusKey;
  poRequestData[0].statusKey = 5;
  this.setState({ poRequestData: poRequestData });

  this.saveData(originalStatusKey);

};

completePO = () => {
  const { poRequestData } = this.state;
  const originalStatusKey = poRequestData[0].statusKey;
  poRequestData[0].statusKey = 7;
  this.setState({ poRequestData: poRequestData });

  this.saveData(originalStatusKey);

};

revisePO = () => {
  const { poRequestData } = this.state;
  const originalStatusKey = poRequestData[0].statusKey;
  poRequestData[0].statusKey = 2;
  this.setState({ poRequestData: poRequestData });

  this.saveData(originalStatusKey);

};

statusFieldReadOnly = (value) => {
  const { poRequestData, user, poRequestKey, isSecurityAdminLogin } = this.state;
  var returnValue = false;

  if (poRequestKey < 0) {
    returnValue = false;
  } 
  else if(poRequestData[0].statusKey === 3 && isSecurityAdminLogin)
  {
    returnValue = false
  }
  else if (poRequestData[0].statusKey === 3)
  {
    returnValue = true
  }
  else if(poRequestData[0].statusKey === 0 && (getUserADAccount(user.account.userName) ===  poRequestData[0].createdBy))
  {
    returnValue = false
  }
  else if (poRequestData[0].statusKey === 5 || poRequestData[0].statusKey === 7)
  {
    returnValue = true
  }
  else if (poRequestData[0].createdBy !== getUserADAccount(user.account.userName))
  {
    returnValue = true
  }
  else if (poRequestData[0].statusKey === 1 && getUserADAccount(user.account.userName) === poRequestData[0].createdBy)
  {
    returnValue = false
  }
  else if (poRequestData[0].statusKey === 1 && isSecurityAdminLogin)
  {
    returnValue = true
  }



  return returnValue;
}

formatDate = (value) => {
  if (!value) {
      // console.error('The value passed to formatDate is falsy:', value);
      return '';
  }
  
  // Adjust the date to Central Time by subtracting the UTC offset
  const ctOffsetHours = this.isDaylightSavingTime(moment(value)) ? -5 : -6;
  const ctDate = moment(value).add(ctOffsetHours, 'hours');
  
  return ctDate.format('M/D/YYYY h:mm A');
}

// Basic check for DST; might not be accurate for all scenarios
isDaylightSavingTime = (date) => {
  const start = moment(date.year() + "-03-08"); // Second Sunday of March
  const end = moment(date.year() + "-11-01");   // First Sunday of November
  return date.isBetween(start, end);
}

formatString=(value)=>{
  if (!value) {
    // console.error('The value passed to formattedString is falsy:', value);
    return '';
}
const formattedString = value.replace(/\./g, ' ');

return formattedString
}

filterAFE = (v) => {
  const { afeDataDefault } = this.state
  // console.log(v)
  if (v !== null) {
    this.setState({ afeData: afeDataDefault.filter(e => (e.parentCompanyKey == v)) })

  }
}

filterSite = (v) => {
  const { siteDataDefault } = this.state;
  // console.log(siteDataDefault)
  // Ensure v and v.companyID are valid before proceeding
  if (v!== null)
    {
      this.setState({
        siteData: siteDataDefault.filter(e =>
          // Ensure e.companyID is a string before using replace/trim/toUpperCase
          typeof e.companyID === 'string' &&
          e.companyID.replace(/,/g, '').trim().toUpperCase() === v.trim().toUpperCase() 
        )
      });
    }

  }



  
toggleMsg = () => {
  this.setState({ modalMsg: !this.state.modalMsg })
}



  render() {
    const {
      poRequestKey,
      isPORequestDataLoaded,
      poRequestData,
      supplierCat,
      entity,
      entityDropDownRef,
      supplierCatDropDownRef,
      afeData,
      afeDropDownRef,
      offLineLinkKey,
      activeTab,
      siteData,
      spendCategory,
      supplier,
      validDataItem,
      isSecurityAdminLogin,
      user,
      msgHeader,
      msgBody,
      isSupplierLoaded
    } = this.state;
    if (!isPORequestDataLoaded) 
      return <div>Loading WO data.....
      </div>;
    else
      return (
        <div>
          <FormGroup>
            <Row form>
              <Col md={6} className="d-flex align-items-center">
                {/* Close Button */}
                <Button
                  color="secondary"
                  className="mr-2"
                  onClick={() => this.closeForm()}
                >
                  Close
                </Button>

                {/* Save Button */}
                <Button
                  color="primary"
                  className="mr-2"
                  onClick={() => this.saveData()}
                  hidden={this.statusFieldReadOnly()}
                >
                  Save
                </Button>

                {/* Submit ConfirmModal */}
                <ConfirmModal
                  color="success"
                  buttonLabel="Submit"
                  className="mr-2"
                  hidden={
                    (poRequestData[0].poRequestKey > 0 &&
                      poRequestData[0].createdBy !==
                        getUserADAccount(user.account.userName)) ||
                    (poRequestData[0].statusKey !== 0 &&
                      poRequestData[0].statusKey !== 1 &&
                      poRequestData[0].statusKey !== 2)
                  }
                  formName="Success"
                  message="Are you sure you want to submit PO?"
                  onClickYes={(e) => this.submitPO()}
                ></ConfirmModal>

                {/* Revise ConfirmModal */}
                <ConfirmModal
                  color="info"
                  buttonLabel="Revise"
                  className="mr-2"
                  hidden={
                    isSecurityAdminLogin ||
                    poRequestData[0].createdBy !==
                      getUserADAccount(user.account.userName) ||
                    poRequestKey < 0 ||
                    poRequestData[0].statusKey !== 3
                  }
                  formName="Revise"
                  message="Are you sure you want to revise selected PO?"
                  onClickYes={(e) => this.revisePO()}
                ></ConfirmModal>

                <ConfirmModal
                  color="success"
                  buttonLabel="Complete"
                  className="mr-2"
                  hidden={
                    poRequestKey < 0 ||
                    poRequestData[0].statusKey !== 3 ||
                    !isSecurityAdminLogin
                  }
                  disabled={
                    poRequestData[0].workdayPOID == null ||
                    poRequestData[0].workdayPOID == ""
                  }
                  formName="Complete"
                  message="Are you sure you want to complete selected PO?"
                  onClickYes={(e) => this.completePO()}
                ></ConfirmModal>
              </Col>

              <Col
                md={6}
                className="d-flex align-items-center justify-content-end"
              >
                {/* Reject ConfirmModal */}
                <ConfirmModal
                  color="danger"
                  className="mr-2"
                  buttonLabel="Reject"
                  hidden={
                    (poRequestData[0].statusKey === 3 &&
                      poRequestData[0].createdBy ===
                        getUserADAccount(user.account.userName)) ||
                    !isSecurityAdminLogin ||
                    poRequestKey < 0 ||
                    poRequestData[0].statusKey == 0 ||
                    poRequestData[0].statusKey === 4 ||
                    poRequestData[0].statusKey === 1 ||
                    poRequestData[0].statusKey === 6 ||
                    poRequestData[0].statusKey === 5 ||
                    poRequestData[0].statusKey === 7 ||
                    poRequestData[0].statusKey === 2
                  }
                  formName="Reject"
                  message="Are you sure you want to reject selected PO?"
                  onClickYes={(e) => this.rejectPO()}
                ></ConfirmModal>

                {/* Cancel ConfirmModal */}
                <ConfirmModal
                  color="danger"
                  className="mr-2"
                  buttonLabel="Cancel"
                  hidden={
                    poRequestData[0].createdBy !==
                      getUserADAccount(user.account.userName) ||
                    poRequestKey < 0 ||
                    poRequestData[0].statusKey == 0 ||
                    poRequestData[0].statusKey === 6 ||
                    poRequestData[0].statusKey === 5 ||
                    poRequestData[0].statusKey === 7
                  }
                  formName="Cancel"
                  message="Are you sure you want to cancel selected PO?"
                  onClickYes={(e) => this.cancelPO()}
                ></ConfirmModal>

                {/* Delete ConfirmModal */}
                <ConfirmModal
                  outline={true}
                  color="danger"
                  buttonLabel="Delete"
                  hidden={
                    poRequestData[0].createdBy !==
                      getUserADAccount(user.account.userName) ||
                    poRequestKey < 0 ||
                    poRequestData[0].statusKey !== 0
                  }
                  formName="Delete"
                  message="Are you sure you want to delete selected PO?"
                  onClickYes={(e) => this.deletePORequest()}
                  image={
                    <img
                      src={TrashBin}
                      id={poRequestKey}
                      alt="Delete"
                      style={{ width: "20px", margin: "0 5px 0 0" }}
                    />
                  }
                ></ConfirmModal>
              </Col>
            </Row>
          </FormGroup>

          {/* <FormGroup>
  <Row className="justify-content-between">
    <Col md={12}>
      <div className="d-flex">

      <Button
          color="secondary"
          className="mr-2"
          onClick={() => this.closeForm()}
        >
          Close
        </Button>

        <Button
          color="primary"
          style={{ margin: "0px 10px 0px 0px" }}
          onClick={() => this.saveData()}
          hidden={this.statusFieldReadOnly()}
        >
          Save
        </Button>
        
      <ConfirmModal
        color="success"
        buttonLabel="Submit"
        hidden={poRequestKey > 0}
        // className="float-left"
        formName="Success"
        message="Are you sure you want to submit PO?"
        onClickYes={(e) => this.submitPO()}
      ></ConfirmModal>

      </div>

    </Col>
  </Row>
</FormGroup> */}

          <ColoredLine color="grey" />

          <div style={{ margin: "20px 0" }}></div>
          <FormGroup>
            <Row form>
              <Col>
                <Row form style={{ marginBottom: "5px" }}>
                  <Col md={4}>
                    <Label for="typeKey">WaterBridge Entity</Label>
                  </Col>
                  <Col md={poRequestKey < 0 ? 8 : 8}>
                    <Select
                      options={entity}
                      className={
                        !validDataItem.entity
                          ? "alway-on-top-more-more form-error"
                          : "alway-on-top-more-more"
                      }
                      // invalid={!validDataItem.entity}
                      value={this.getSelEntity(poRequestData[0].entity)}
                      onChange={(e) => this.onTextChange("entity", e, e)}
                      placeholder=""
                      isDisabled={this.statusFieldReadOnly()}
                      isClearable={true}
                    />
                  </Col>
                </Row>

                <Row form style={{ marginBottom: "5px" }} >
                  <Col md={4}>
                    <Label for="typeKey">Supplier Name</Label>
                  </Col>
                  <Col md={poRequestKey < 0 ? 8 : 8} >
                    {/* <TextBoxComponent value={poRequestData[0].supplierName} /> */}
                    <Creatable
                      options={supplier}
                      className={!validDataItem.supplierID ? "alway-on-top-more" : "alway-on-top-more"}
                      value={this.getSelSupplier(poRequestData[0].supplierID)}
                      isDisabled={this.statusFieldReadOnly()}
                      onChange={(e) => this.onTextChange("supplierID", e, e)}
                      isClearable={true}
                    />
                  </Col>
                </Row>

                <Row form style={{ marginBottom: "5px" }}>
                  <Col md={4}>
                    <Label for="typeKey">Supplier Contact</Label>
                  </Col>
                  <Col md={poRequestKey < 0 ? 8 : 8} className={""}>
                    {/* <TextBoxComponent value={poRequestData[0].emailAddress} /> */}
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      // invalid={!validDataItem.summaryOfRequestedChange}
                      className={
                        !validDataItem.emailAddress ? "form-error" : ""
                      }
                      disabled={this.statusFieldReadOnly()}
                      placeholder=""
                      value={poRequestData[0].emailAddress}
                      onChange={(e) =>
                        this.onTextChange("emailAddress", e.target.value, e)
                      }
                    />
                  </Col>
                </Row>

                <Row form style={{ marginBottom: "5px" }}>
                  <Col md={4}>
                    <Label for="typeKey">Spend Category</Label>
                  </Col>
                  <Col md={poRequestKey < 0 ? 8 : 8} className={""}>
                    <Select
                      options={spendCategory}
                      id="spendCategory"
                      value={this.getSelSpendCat(
                        poRequestData[0].spendCategory
                      )}
                      // hidden={console.log(poRequestData[0].spendCategory)}
                      // value={console.log(poRequestData[0].spendCategory)}
                      className={
                        !validDataItem.spendCategory
                          ? "alway-on-top form-error"
                          : "alway-on-top"
                      }
                      isDisabled={this.statusFieldReadOnly()}
                      isClearable={true}
                      placeholder=""
                      isMulti
                      onChange={(e) => this.onTextChange("spendCategory", e, e)}
                    />
                  </Col>
                </Row>

                <Row form style={{ marginBottom: "5px" }}>
                  <Col md={4}>
                    <Label for="typeKey">AFE No</Label>
                  </Col>
                  <Col md={poRequestKey < 0 ? 8 : 8} className={""}>
                    <Select
                      id="afe"
                      value={this.getSelAFE(poRequestData[0].afeid)}
                      isDisabled={this.statusFieldReadOnly()}
                      options={afeData}
                      placeholder=""
                      isClearable={true}
                      onChange={(e) => this.onTextChange("afeid", e, e)}
                    />
                  </Col>
                </Row>

                <Row form style={{ marginBottom: "5px" }}>
                  <Col md={4}>
                    <Label for="typeKey">Site</Label>
                  </Col>
                  <Col md={poRequestKey < 0 ? 8 : 8} className={""}>
                    {/* <TextBoxComponent value={poRequestData[0].site} /> */}
                    <Creatable
                      options={siteData}
                      name="site"
                      id="site"
                      onChange={(e) => this.onTextChange("siteID", e, e)}
                      placeholder=""
                      value={this.getSelSite(poRequestData[0].siteID)}
                      isDisabled={
                        this.statusFieldReadOnly() ||
                        (poRequestData[0].afeid !== null &&
                          poRequestData[0].afeid !== "")
                      }
                      isClearable={true}
                    />
                  </Col>
                </Row>

                <Row form style={{ marginBottom: "5px" }}>
                  <Col md={4}>
                    <Label for="typeKey">Amount</Label>
                  </Col>
                  <Col md={poRequestKey < 0 ? 8 : 8} className={""}>
                    {/* <NumericTextBoxComponent
                      value={poRequestData[0].amount}
                      format="c2"
                    /> */}
                    <NumberFormat
                      className={`form-control ${
                        !validDataItem.amount ? "form-error" : ""
                      }`}
                      id="amount"
                      style={{ textAlign: "right" }}
                      disabled={this.statusFieldReadOnly()}
                      value={poRequestData[0].amount}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      thousandSeparator={true}
                      prefix={"$"}
                      onChange={(e) => {
                        let value = e.target.value.replace(/[^0-9.]/g, ""); // Remove any non-digit characters (except for the decimal point)
                        if (value === "" || parseFloat(value) <= 0) {
                          this.onTextChange("amount", "0.00", e);
                        } else {
                          this.onTextChange("amount", value, e);
                        }
                      }}
                    />
                  </Col>
                </Row>

                <Row form>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          this.toggleTab("1");
                        }}
                      >
                        Comments
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          this.toggleTab("2");
                        }}
                      >
                        Documents
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Row>

                <Row form>
                  <Col md={poRequestData[0].poRequestKey < 0 ? 12 : 12}>
                    <TabContent
                      activeTab={activeTab}
                      className={"tab-content-container"}
                    >
                      <TabPane tabId="1">
                        <Input
                          className={
                            !validDataItem.comments ? "form-error" : ""
                          }
                          type="textarea"
                          id="comments"
                          rows={6}
                          placeholder=""
                          value={poRequestData[0].comments}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.onTextChange("comments", e.target.value, e)
                          }
                          disabled={this.statusFieldReadOnly()}
                        />
                      </TabPane>

                      <TabPane tabId="2">
                        <DocumentBrowserDragDrop
                          buttonLabel="View Image"
                          buttonColor="primary"
                          formName="PO Request Documents"
                          linkTableName={"tpoPORequest"}
                          linkPrimaryKey={
                            poRequestKey < 0 ? offLineLinkKey : poRequestKey
                          }
                          // apiURL={
                          //   apiURL +
                          //   "AP/SupplierRequest/Documents/" +
                          //   (poRequestKey < 0 ? offLineLinkKey : poRequestKey)
                          // }
                          apiURL={
                            apiURL +
                            "SupplyChain/PORequest/Documents/" +
                            (poRequestKey < 0 ? offLineLinkKey : poRequestKey)
                          }
                        />
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </Col>

              <Col md={5} hidden={poRequestKey < 0}>
                <Row form style={{ marginBottom: "5px" }}>
                  <Col md={4}>
                    <Label for="requestNo">PO No</Label>
                  </Col>

                  <Col md={4}>
                    <Input
                      type="text"
                      name="poReqID"
                      value={poRequestData[0].poReqID}
                      disabled
                    />
                  </Col>
                  <Col md={4}>
                    <Input
                      type="text"
                      name="statusKey"
                      value={poRequestData[0].poRequestStatusDesc}
                      disabled
                    />
                  </Col>
                </Row>

                <Row form style={{ marginBottom: "5px" }}>
                  <Col md={4}>
                    <Label for="requestNo">Requested By</Label>
                  </Col>

                  <Col md={8}>
                    <Input
                      type="text"
                      name="requestedBy"
                      value={this.formatString(poRequestData[0].createdBy)}
                      disabled
                    />
                  </Col>
                </Row>

                <Row form style={{ marginBottom: "5px" }}>
                  <Col md={4}>
                    <Label for="requestNo">Requested Date</Label>
                  </Col>

                  <Col md={8}>
                    <Input
                      type="text"
                      name="createdDatetime"
                      value={this.formatDate(poRequestData[0].createdDatetime)}
                      disabled
                    />
                  </Col>
                </Row>

                <Row form style={{ marginBottom: "5px" }}>
                  <Col md={4}>
                    <Label for="requestNo">Modified By</Label>
                  </Col>

                  <Col md={8}>
                    <Input
                      type="text"
                      name="modifiedBy"
                      value={this.formatString(poRequestData[0].modifiedBy)}
                      disabled
                    />
                  </Col>
                </Row>

                <Row form style={{ marginBottom: "5px" }}>
                  <Col md={4}>
                    <Label for="requestNo">Modified Date</Label>
                  </Col>

                  <Col md={8}>
                    <Input
                      type="text"
                      name="modifiedDatetime"
                      value={this.formatDate(poRequestData[0].modifiedDateTime)}
                      disabled
                    />
                  </Col>
                </Row>

                <Row form style={{ marginBottom: "5px" }}>
                  <Col md={4}>
                    <Label for="typeKey">WD PO #</Label>
                  </Col>
                  <Col md={8} className={""}>
                    <Input
                      type="text"
                      name="workdayPOID"
                      value={poRequestData[0].workdayPOID}
                      disabled={
                        this.statusFieldReadOnly() || !isSecurityAdminLogin
                      }
                      onChange={(e) =>
                        this.onTextChange("workdayPOID", e.target.value, e)
                      }
                    />
                  </Col>
                </Row>

                <Row form style={{ marginBottom: "5px" }}>
                  <Col md={4}>
                    <Label for="typeKey">Admin Comments</Label>
                  </Col>
                  <Col md={8} className={""}>
                    <Input
                      type="textarea"
                      rows={10}
                      name="workdayPOID"
                      value={poRequestData[0].adminComments}
                      disabled={
                        this.statusFieldReadOnly() || !isSecurityAdminLogin
                      }
                      onChange={(e) =>
                        this.onTextChange("adminComments", e.target.value, e)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormGroup>

          {/* <Form>
            <Row>
              <Col md={12}>
                <div className="float-right">
                  <Button
                    color="secondary"
                    className="float-right"
                    onClick={() => this.closeForm()}
                  >
                    Close
                  </Button>

                  <ConfirmModal
                    color="success"
                    className="float-right"
                    buttonLabel="Submit"
                    style={{ margin: "0px 10px 0px 0px" }}
                    // hidden={this.statusButtonReadOnly("submit")}
                    hidden={poRequestKey > 0}
                    formName="Success"
                    message="Are you sure you want to submit PO?"
                    onClickYes={(e) => this.submitPO()}
                  ></ConfirmModal>

                  <Button
                    color="primary"
                    style={{ margin: "0px 10px 0px 0px" }}
                    // hidden={readOnly}
                    onClick={() => this.saveData()}
                  >
                    {poRequestKey < 0 ? "OK" : "Save"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form> */}

          {/* <Form>
            <Row>
              <Col md={6}>
              <ConfirmModal
                    color="success"
                    buttonLabel="Submit"
                    hidden={poRequestKey > 0}
                    className ="float-left"
                    // hidden={console.log(poRequestKey)}
                    formName="Success"
                    message="Are you sure you want to submit PO?"
                    onClickYes={(e) => this.submitPO()}
                    // className="mr-2" // Added margin to the right
                  ></ConfirmModal>
                </Col>
              <Col md={6}>
                <div className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    style={{ margin: "0px 10px 0px 0px" }}
                    onClick={() => this.saveData()}
                    hidden={this.statusFieldReadOnly()}
                  >
                    Save
                  </Button>


                  <Button
                    color="secondary"
                    className="mr-2" // Added margin to the right
                    onClick={() => this.closeForm()}
                  >
                    Close
                  </Button>
                </div>
              </Col>
            </Row>
          </Form> */}

          <div>
            <Modal
              isOpen={this.state.modalMsg}
              toggle={() => this.toggleMsg()}
              size="lg"
            >
              <ModalHeader toggle={() => this.toggleMsg()}>
                {msgHeader}
              </ModalHeader>
              <ModalBody>{msgBody}</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => this.toggleMsg()}>
                  OK
                </Button>{" "}
              </ModalFooter>
            </Modal>
          </div>
        </div>
      );
  }
}

